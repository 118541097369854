@import 'handsontable/dist/handsontable.full.css';

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #dedede4d; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sized-container-div{
  height: 80vh;
  width: 100%;
}

.extracted-html h1 {
  font-size: 36px;
  padding-top: 18px;
}

.extracted-html h2 {
  font-size: 24px;
  padding-top: 12px;
}

.extracted-html table {
  margin: 24px !important;
  padding: 12px !important;

  border: solid 2px rgba(88, 88, 88, 0.897);
  border-radius: 5px;
}

.extracted-html table tr {
  padding: 12px !important;
  border: solid 1px rgb(190, 190, 190) !important;

}
.extracted-html table thead {
  padding: 12px !important;
  border: solid 1px rgb(190, 190, 190)  !important;

}
.extracted-html table td {
  padding: 6px !important;
}