/* @import "~antd/dist/antd.css"; */

body {
  margin: 0;
  font-family: "IBM Plex Sans", "PT Sans", "Rubik", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fdfffc !important;
  overscroll-behavior-x: none;
}

.ant-page-header {
  padding: 0px 0px !important;
  margin: 12px 0px !important;
  background-color: transparent !important;
}

/* .ant-row {
  margin: 12px 0px !important;
  padding: 0px 12px !important;
  background: none;
} */

.search-row {
  margin: 12px 0px !important;
  /* padding: 0px 12px !important; */
  background: none;
}

.overline {
  font-size: 12px;
  letter-spacing: 0.08rem;
  color: #6d8c98;
  margin-top: 24px;
  text-transform: uppercase;
}

.pdf-view {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
  /* border: none; */
  -webkit-transition: box-shadow 0.3s ease-in-out !important;
  -moz-transition: box-shadow 0.3s ease-in-out !important;
  -ms-transition: box-shadow 0.3s ease-in-out !important;
  -o-transition: box-shadow 0.3s ease-in-out !important;
  transition: box-shadow 0.3s ease-in-out !important;
  height: 800px;
  overflow-y: scroll;
}

.file-card {
  margin: 0px 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;
  /* border: none; */
  -webkit-transition: box-shadow 0.3s ease-in-out !important;
  -moz-transition: box-shadow 0.3s ease-in-out !important;
  -ms-transition: box-shadow 0.3s ease-in-out !important;
  -o-transition: box-shadow 0.3s ease-in-out !important;
  transition: box-shadow 0.3s ease-in-out !important;
}

.file-card-selected {
  margin: 0px 5px;
  border-color: rgba(107, 114, 128, 0.9) !important;
  box-shadow: 0 0 0 3px rgb(0 0 0 / 25%) !important;
}

.candidate-card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;
  /* border: none; */
  -webkit-transition: box-shadow 0.3s ease-in-out !important;
  -moz-transition: box-shadow 0.3s ease-in-out !important;
  -ms-transition: box-shadow 0.3s ease-in-out !important;
  -o-transition: box-shadow 0.3s ease-in-out !important;
  transition: box-shadow 0.3s ease-in-out !important;
}
.file-card:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
  /* border: none; */
  -webkit-transition: box-shadow 0.3s ease-in-out !important;
  -moz-transition: box-shadow 0.3s ease-in-out !important;
  -ms-transition: box-shadow 0.3s ease-in-out !important;
  -o-transition: box-shadow 0.3s ease-in-out !important;
  transition: box-shadow 0.3s ease-in-out !important;
}

.hover-button-invisible {
  /* display: none; */
  display: inline-block;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out !important;
  -moz-transition: opacity 0.3s ease-in-out !important;
  -ms-transition: opacity 0.3s ease-in-out !important;
  -o-transition: opacity 0.3s ease-in-out !important;
  transition: opacity 0.3s ease-in-out !important;
}

.hover-button-visible {
  opacity: 1;
  display: inline-block;
  -webkit-transition: opacity 0.3s ease-in-out !important;
  -moz-transition: opacity 0.3s ease-in-out !important;
  -ms-transition: opacity 0.3s ease-in-out !important;
  -o-transition: opacity 0.3s ease-in-out !important;
  transition: opacity 0.3s ease-in-out !important;
}

.job-tag {
  background-color: #6d8c988a !important;
  color: #0c495d !important;
  text-transform: uppercase !important;
  padding: 2px 8px !important;
  margin-bottom: 10px !important;
  border: none !important;
}
.ant-menu-item {
  border-radius: 5px !important;
  padding-left: 0px !important;
}

.ant-layout-sider {
  background-color: #779CAB !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent !important;
}

.ant-layout {
  height: 100vh;
  background: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.page-content {
  margin: 24px 18px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menuBar {
  padding: 0 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
}

.logo {
  width: 150px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}

.menuCon {
  width: 100%;
  /* width: calc(100% - 150px); */
  /* float: left; */
  /* height: 80px; */
}

.ant-layout {
  height: 100vh;
  overflow-y: scroll;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
  padding-left: 0px !important;
}

/* .menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
} */

/* .menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
} */

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuCon .rightMenu {
  width: 100%;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu > span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}
/* 
@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 10px 20px;
  }
} */

.ant-menu-item span {
  line-height: 24px;
}

.text-highlight {
  background-color: rgba(172, 255, 47, 0.336);
}

#inline-toolbar {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e8e8eb;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 5px;
  border-radius: 6px;
  z-index: 2;
  display: none;
  right: 0;

}
#inline-toolbar-new{
  position: absolute;
  background-color: #fff;
  border: 1px solid #e8e8eb;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 5px;
  border-radius: 6px;
  z-index: 2;
  display: none;
  right: 0;
}
.entity-span {
  background-color: #ddd;
  cursor: pointer;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  padding: 2px;
  line-height: 1;
  border-radius: 4px;
}

#inline-toolbar-selection-text{
  padding-left: 10px;
font-family: 'Circular-Loom';
}

.entity-check-loading{
  display: none;
  font-size: 20px;
  /* padding-right: 5px; */
  margin-right: 10px;
}

.entity-check-icon{
  display: inline-block;
}

.entity-BILL_SECTION {
  background-color: #EF6F6C
}

.entity-SERVICE_NUMBER {
  background-color: #00C2D1
}

.entity-PRODUCT {
  background-color: #F6AF65
}

.entity-TOTAL {
  background-color: #BEA7E5
}

.entity-PEOPLE {
  background-color: #66C3FF
}

.entity-MONEY {
  background-color: #F9E900
}

.entity-DATE {
  background-color: #D4AFB9
}

.entity-TIME {
  background-color: #B0E298
}

.entity-DATA_UNITS {
  background-color: #BEA7E5
}

.entity-CALL_UNITS {
  background-color: #7FB685
}

.entity-TEXT_UNITS {
  background-color: #9AC2C9
}

.entity-TEXT_UNITS {
  background-color: #0A1045
}

.ant-list .ant-row {
  width: 100%;
}

.watermark{
 position:absolute;
 bottom:50%;
 right:50%;
 opacity:0.2;
 z-index: 0;
 color:white;
}

.product-release-heading {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 32px;
  font-weight: 500;
  /* letter-spacing: 0.05em; */
  line-height: 150%;
  text-transform: uppercase;
margin-bottom: 1.5rem;
margin-left: 2.5rem;

}

.product-release-subheading {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.0725em;
  line-height: 150%;
  text-transform: uppercase;
margin-bottom: 1rem;
}

.product-release-text {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 18px;
  font-weight: 400;
}