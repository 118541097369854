.sidebar {
  height: 100vh;
  margin-right: 24px;
}
.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
}

@media (min-width: 992px) {
  .navbar {
    display: none;
  }
}
/* 
.logo {
  height: 32px;
  margin-left: 1rem;
} */
